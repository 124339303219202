<template>
  <div class="relative">
    <!-- menu tabs -->
    <div :style="{top: stickyTop}" class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 border-b border-gray-200 bg-white z-10 sticky">
      <nav class="flex justify-between md:space-x-8 md:justify-start" aria-label="Tabs">
        <a
          v-for="tab in tabs"
          :key="tab.name"
          :class="[(currentTab === tab.slug) ? 'border-ifgreen text-ifgreen' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300', 'whitespace-nowrap w-1/2 py-4 px-1 border-b-2 font-medium text-sm']"
          @click="currentTab = tab.slug"
        >
          {{ tab.name }}
        </a>
      </nav>
    </div>

    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <ServicesPagination
        v-for="tab in tabs"
        :key="tab.slug"
        v-show="currentTab === tab.slug"
        :status="tab.slug"
        :shortened="shortened"
        :installationId="installationId"
        :emptyMessage="tab.emptyMessage"
      />
    </div>
  </div>
</template>

<script>
import ServicesPagination from './ServicesPagination.vue'
import { PhoneIcon } from '@heroicons/vue/24/solid'
import { ClockIcon } from '@heroicons/vue/24/outline'

export default {
  components: {
    PhoneIcon,
    ClockIcon,
    ServicesPagination
  },
  props: {
    shortened: Boolean,
    stickyTop: String,
    installationId: Number
  },
  data(){
    return{
      tabs: [
        { name: `${this.$t('service.pending')}`, slug: 'pending', emptyMessage: this.$t('service.noServiceAwaits')},
        { name: `${this.$t('service.completed')}`, slug: 'done', emptyMessage: this.$t('service.noCompletedServices')},
        { name: `${this.$t('service.rejectedPlural')}`, slug: 'rejected', emptyMessage: this.$t('service.noRejectedServices')}
      ],
      currentTab: 'pending',
      actionInspection: null
    }
  }
}
</script>
