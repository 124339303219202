<template>
    <div v-if="services[status].data && services[status].data.length">
        <Pagination :pagination="services[status].pagination" entity="services" :status="status" />
        <ul class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
            <ServiceCard
                v-for="(service, index) in services[status].data"
                :key="index"
                :service="service"
                :shortened="shortened"
            />
        </ul>
        <Pagination :pagination="services[status].pagination" entity="services" :status="status" />
    </div>
    <p v-else class="mt-6">
        {{ emptyMessage }}
    </p>
</template>

<script>
import Pagination from '@/components/layout/Pagination.vue'
import ServiceCard from '@/components/services/ServiceCard.vue'
import { mapState } from 'vuex'

export default {
    name: 'ServicesPagination',
    components: {
        Pagination,
        ServiceCard
    },
    props: {
        shortened: Boolean,
        stickyTop: String,
        emptyMessage: String,
        installationId: Number,
        status: String
    },
    computed: {
        ...mapState(['services'])
    },
    async created() {
        const searchParams = new URLSearchParams()

        let status = this.status
        let key = this.status
        if (this.status == 'pending') {
            status = null
            searchParams.append('status[]', 'require_spare_parts')
            searchParams.append('status[]', 'spare_parts_sent') 
        }

        if (this.installationId) {
           searchParams.append('customDevice.installation.id', this.installationId)
        }

        const query = searchParams.toString() ? `&${searchParams.toString()}` : ''

        this.$store.dispatch('setLoading', true)
        await this.$store.dispatch('getCollection', { entity: 'services', key, status, page: 1, query, installationId: this.installationId })
        this.$store.dispatch('setLoading', false)
    },
}
</script>