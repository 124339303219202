import { s3url, resolveWarrantyAttachments } from '@/assets/js/helpers.js'

export default {
  computed: {
    deviceDownloadables () {
      if (this.installation && this.installation.status != 'no_warranty' && this.installationDevice && this.installationDevice?.schemaVersion?.installationFormSchema) {

        const lang = this.installation.language

        const legacyTranslation = {
          'ext unit': 'Zdjęcie jednostki zewnętrznej',
          'termic insulation': 'Zdjęcie izolacji termicznej',
          'condensate drain': 'Zdjęcie odprowadzenia kondensatu',
          'int unit': 'Zdjęcie jednostki wewnętrznej',
          'hydraulic connections': 'Zdjęcie hydrauliki',
          'electric board': 'Zdjęcie tablicy elektrycznej klienta',
          'pressure reductor': 'Zdjęcie nastawy reduktora ciśnienia'
        }

        let id = this.installationDevice.deviceExtension && this.installationDevice.deviceExtension.legacy ? (this.installationDevice.parameters ? this.installationDevice.parameters.numer_seryjny : '') : this.installationDevice.id

        let images = []

        this.installationDevice.schemaVersion.installationFormSchema.forEach((step, stepIndex) => {
          step.schema.forEach(field => {
            if (field.type =='image' && (!this.installation.survey || !this.installation.survey?.data || this.installation.survey?.data?.[stepIndex][field.imageName])) {
              images.push({label: 
                field.imageLabels?.[lang] ? field.imageLabels[lang] : field.imageLabel, 
                link: `${s3url}${id}-${field.imageName}.jpg`})
            }
          })
        })

        if (this.installationDevice.deviceTemplate.isSpecial) {
          images = images.map(image => {
            if (legacyTranslation[image.label]) return {label: legacyTranslation[image.label], link: image.link}
            else return {label: image.label, link: image.link}
          })
        }

        let dowloadables = [
          {label: this.$t('installation.installationCard'), link: `${s3url}${id}-installation-card.pdf`},
          ...images
        ]

        if (this.installation.status == 'accepted') {
          let formatedWarrantyAttachments = []
          if (this.installationDevice.schemaVersion.warrantySchema && this.installationDevice.schemaVersion.warrantySchema[lang]) {
            const warrantyAttachments = resolveWarrantyAttachments(this.installationDevice.schemaVersion.warrantySchema[lang].attachments, this.installation)
            
            formatedWarrantyAttachments = warrantyAttachments.map(attachment => {
              return {label: attachment.title, link: attachment.href}
            })
          }
          dowloadables = [
            {label: this.$t('installation.warrantyCard'), link: `${s3url}${id}-warranty-card.pdf`},
            ...formatedWarrantyAttachments,
            ...dowloadables
          ]
        }

        return dowloadables
      } else {
        return []
      }
    }
  }
}
