<template>
  <div v-if="installation && installation.status === 'no_device'">
    <h2 class="mt-4 text-3xl font-extrabold text-gray-900">
      {{$t("device.addDevice")}}
    </h2>
    <div v-if="deviceTemplates" class="mt-6 pb-6">
      <div v-if="deviceTemplatesOptions.length > 1" class="mb-4">
        <NativeSelect
          v-model="deviceTemplateId"
          :placeholder="$t('device.selectDeviceType')"
          :options="deviceTemplatesOptions"
        />
      </div>
      <BarcodeScanner
        v-model="serialNumber"
        :text="$t('home.barcodeScanner.insertSerialNumber')"
        :placeholder="$t('home.barcodeScanner.placeholder')"
        @scan-completed="checkSerialNumber"
      />
      <div v-if="deviceTemplates.length > 1 && !isSpecialTemplate">
        <button @click="addCustomDevice" type="button" class="mt-4 inline-flex w-full items-center justify-center px-6 py-3 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-ifgreen hover:bg-ifgreen-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ifgreen">
          <CogIcon class="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
          {{$t("device.initiate")}}
        </button>
      </div>
    </div>
  </div>
  <div v-else class="py-4">
    <div class="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
      <div class="flex-shrink-0">
        <ClockIcon v-if="installation.status === 'pending'" class="h-10 w-10" />
        <CheckCircleIcon v-if="installation.status === 'accepted'" class="h-10 w-10 text-ifgreen" />
        <ExclamationCircleIcon v-if="installation.status === 'rejected'" class="h-10 w-10 text-red-500" />
      </div>
      <div class="flex-1 min-w-0">
        <a href="#" class="focus:outline-none">
          <span class="absolute inset-0" aria-hidden="true" />
          <p v-if="installation.status !== 'no_warranty'" class="text-sm font-medium text-gray-900">
            {{ installation.status === 'pending' ? $t("device.awaitingVerification") : (installation.status === 'accepted' ? $t("device.warrantyIssued") : $t("device.installationRejected")) }}
          </p>
          <p v-else class="text-sm font-medium text-gray-900">
           {{$t("device.installationOutsideApp")}}
          </p>
          <p v-if="installation.rejectionReason" class="text-sm font-medium text-gray-900">
            {{$t("installation.notes")}}: {{ installation.rejectionReason }}
          </p>
          <p class="text-sm text-gray-500 truncate">
            {{ installationDevice.deviceTemplate ? installationDevice.deviceTemplate.name : installationDevice.id }}
          </p>
        </a>
      </div>
    </div>
    <button v-if="installation.status === 'rejected'" @click="installAgain" type="button" class="mt-4 inline-flex w-full items-center justify-center px-6 py-3 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-ifgreen hover:bg-ifgreen-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ifgreen">
      <CogIcon class="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
      {{$t("device.reinstalling")}}
    </button>
    <div class="flex flex-col space-y-3 mt-6">
      <div v-for="(entry, index) in deviceDownloadables" :key="index">
        <div class="border-b border-gray-300">
          <a :href="entry.link" target="_blank" class="px-2 py-3 text-sm font-medium text-gray-900 w-full flex justify-between">
            {{ entry.label }}
            <ArrowDownTrayIcon class="w-4 h-4" />
          </a>
        </div>
      </div>
    </div>
  </div>
  <Modal
    ref="modal"
    :title="serialNumber"
    :text="modalText"
    :cancel-button-label="$t('cancel')"
  >
    <button
      v-if="modalAction === 'new-device' || modalAction === 'offline'"
      type="button"
      class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-ifgreen text-base font-medium text-white hover:bg-ifgreen-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ifgreen sm:col-start-2 sm:text-sm"
      @click="$router.push({path: `/installations/${$route.params.id}/add-device/new`, query: {offline: modalAction === 'offline' ? true : null}})"
    >
      {{$t("device.initiate")}}
    </button>
  </Modal>
</template>

<script>
import BarcodeScanner from '@/components/inputs/BarcodeScanner.vue'
import NativeSelect from '@/components/inputs/NativeSelect.vue';
import TemplateForm from "@/components/admin/DeviceTemplate/TemplateForm.vue";
import Modal from '@/components/layout/Modal.vue'
import deviceDownloadables from '@/mixins/deviceDownloadables.js'
import { CheckCircleIcon, ExclamationCircleIcon, ClockIcon, ArrowDownTrayIcon, CogIcon } from "@heroicons/vue/24/outline"
import { mapState } from 'vuex'
import { serializeParams, attachTemplate } from '@/assets/js/helpers.js'

export default {
    components: {
      CheckCircleIcon,
      ExclamationCircleIcon,
      ClockIcon,
      ArrowDownTrayIcon,
      CogIcon,
      TemplateForm,
      BarcodeScanner,
      NativeSelect,
      Modal
    },
    mixins: [deviceDownloadables],
    props: {
      installation: Object
    },
    data () {
      return {
        serialNumber: '',
        deviceTemplateId: process.env.VUE_APP_IFDEVICE_TEMPLATE_ID,
        customDeviceParameters: {},
        modalText: '',
        modalAction: null
      }
    },
    computed: {
      ...mapState(['user', 'device', 'installations', 'currentInstallation', 'organisation','deviceTemplates']),
      deviceTemplatesOptions () {
        return this.deviceTemplates
          .filter(template => {return template.isActive && this.user.availableTemplates.includes(`/device_templates/${template.id}`)})
          .map(template => {
            return {
              name: template.nameTranslations?.[this.$i18n.locale] ? template.nameTranslations[this.$i18n.locale] : template.name, 
              value: template.id
            }
          })
      },
      deviceTemplate () {
        const template = this.deviceTemplates && this.deviceTemplateId != 0 && this.deviceTemplates.find(template => {return template.id == this.deviceTemplateId })
        return template
      },
      installationDevice () {
        return serializeParams(attachTemplate(this.installation.customDevice))
      },
      isSpecialTemplate () {
        return this.deviceTemplateId && this.deviceTemplateId == process.env.VUE_APP_IFDEVICE_TEMPLATE_ID
      }
    },
    mounted () {
      if (this.deviceTemplatesOptions[0]) this.deviceTemplateId = this.deviceTemplatesOptions[0].value
    },
    methods: {
      checkSerialNumber () {
        this.$store.dispatch('setLoading', true)
        this.$store.dispatch('getDevice', {deviceTemplate: this.deviceTemplate, id: this.serialNumber}).then((result) => {
          this.$store.dispatch('setLoading', false)
          if (result.status === 'not-found') {
            const idField = this.deviceTemplate.activeVersion.parametersSchema.find(field => {return field.barcodeScanner})
            this.$store.dispatch('saveDevice', {
              deviceTemplate: this.deviceTemplate,
              parameters: {
                [idField.name]: this.serialNumber
              }
            })
            this.modalText = `${this.$t('public.infos.noDeviceWithSerialNumber')}`
            this.modalAction = "new-device"
            this.$nextTick(() => { this.$refs.modal.show() })
          } else if (this.device.installation && this.device.installation.status != 'rejected') {
            this.modalText = `${this.$t('public.infos.deviceInstalled')}`
            this.modalAction = "mounted-device"
            this.$nextTick(() => { this.$refs.modal.show() })
          }
          else {
            console.log(this.device)
            this.$router.push({path: `/installations/${this.$route.params.id}/add-device/${this.device.id}`})
          }
        }).catch(err => {
          console.log(err)
          this.$store.dispatch('setLoading', false)
          const idField = this.deviceTemplate.activeVersion.parametersSchema.find(field => {return field.barcodeScanner})
          this.$store.dispatch('saveDevice', {
            deviceTemplate: this.deviceTemplate,
            parameters: {
              [idField.name]: this.serialNumber
            }
          })
          this.modalText = `${this.$t('device.connectionError')}`
          this.modalAction = "offline"
          this.$nextTick(() => { this.$refs.modal.show() })
        })
      },
      installAgain() {
        this.serialNumber = this.installationDevice.id
        this.deviceTemplateId = null
        this.checkSerialNumber()
      },
      invalid () {
        this.$notify({
          title: `${this.$t('template.warning')}`,
          text:  `${this.$t('device.provideParameters')}`,
          type: 'warning',
          duration: 5000
        })
      },
      addCustomDevice () {
        this.$store.dispatch('saveDevice', {
          deviceTemplate: this.deviceTemplate,
          parameters: {}
        })
        this.$router.push({path: `/installations/${this.$route.params.id}/add-device/new`})
      }
    }
}
</script>
